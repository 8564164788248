import { FC, createContext } from "react";
import { usePersistentStorage } from "src/CustomHooks";

export const prodUrl = "https://api.giyim.app";
export const devUrl = "https://localhost:7048";
export const prodImagesUrl = "https://images.giyim.app";
export const subdomainSuffix = ".giyim.app";
export const appVersion = "v0.1.1";

export const baseUrl = () => {
	return process.env.NODE_ENV === "development" ? devUrl : prodUrl;
};

export type AppContextProps = {
	logoutUser: () => Promise<void>;
	user: ICurrentUser;
	isLoggedIn: boolean;
	setUser: React.Dispatch<ICurrentUser>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export type ChildrenProps = {
	children?: React.ReactNode
};

export const AppContextProvider: FC<ChildrenProps> = ({ children }) => {
	const [user, setUser] = usePersistentStorage<ICurrentUser>("currentUser", {} as ICurrentUser);

	const logoutUser = () => {
		setUser({} as ICurrentUser);
		return Promise.resolve();
	};

	// currentUser returns empty guid if user is not authenticated in
	const isLoggedIn = user && user.tenantId !== undefined && user.tenantId !== "00000000-0000-0000-0000-000000000000";

	return <AppContext.Provider
		value={{
			isLoggedIn,
			user,
			setUser,
			logoutUser,
		}}>
		{children}
	</AppContext.Provider>;
};
