import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import "nprogress/nprogress.css";
import ReactDOM from "react-dom/client";
import App from "src/App";
import * as serviceWorker from "src/serviceWorker";
import "./i18n";

//https://tokyo.bloomui.com/extended-sidebar/dashboards
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<HelmetProvider>
		<BrowserRouter>
			{/* <OtelTracker />Enables OpenTelemetry route tracking */}
			<App />
		</BrowserRouter>
	</HelmetProvider>
);

serviceWorker.unregister();
